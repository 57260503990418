<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('customerFilter')"
                  >
                    <CIcon
                      v-if="!loading"
                      size="lg"
                      name="cil-filter"
                      class="mr-2"
                    />
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right"></div>
          </div>
        </CCardHeader>

        <CCardBody>
          <CDataTable
            :items="customers"
            :fields="fields"
            :items-per-page="10"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            v-on:refresh="filterCustomers"
          >
            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown toggler-text="İşlemler" color="info" class="m-2">
                  <div role="group">
                    <CDropdownItem @click="openModalEvent('update', 'CustomerInfo',item, item.id+' - '+item.firstName+' - '+item.lastName)">
                      Kullanıcı İşlemleri
                    </CDropdownItem>
                    <CDropdownItem @click="openModalEvent('sendNewPassword', 'confirm',item, 'Yeni Şifre Gönder', 'Kullanıcıya yeni şifre gönrmek istediğine emin misin?')"
                      >Yeni Şifre Gönder
                    </CDropdownItem>
                    <CDropdownItem @click="goToOrdersPage(item)"> Siparişleri </CDropdownItem>
                    <CDropdownItem @click="openModalEvent('list', 'CustomerPromecodes',item, item.id+' - '+item.firstName+' - '+item.lastName+' Promosyonları')"
                      >Promosyonları
                    </CDropdownItem>
                    <CDropdownItem @click="openModalEvent('list', 'CustomerAddresses',item, item.id+' - '+item.firstName+' - '+item.lastName+' Adresleri')">
                      Adresi
                    </CDropdownItem>
                    <CDropdownItem @click="openModalEvent('list', 'CustomerAlertLog',item, item.id+' - '+item.firstName+' - '+item.lastName+' Hata Logları')">
                      Hata Logları
                    </CDropdownItem>
                  </div>
                </CDropdown>
              </td>
            </template>
            <template #createTime="{item}">
              <td> {{item.createTime ? dateFormat(item.createTime): ''}}</td>
            </template>

            <template #lastLoginTime="{item}">
              <td> {{item.lastLoginTime ? dateFormat(item.lastLoginTime) : ''}}</td>
            </template>
            <template #loginType="{item}">
             <td> {{item.loginType ? item.loginType.type : ''}} </td> 
            </template>
            <template #activeFlag="{item}">
              <td>
                <CIcon
                  v-if="item.activeFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #emailValidFlag="{item}">
              <td>
                <CIcon
                  v-if="item.emailValidFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #guestUserFlag="{item}">
              <td>
                <CIcon
                  v-if="item.guestUserFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #gender="{item}">
              <td>
                <CIcon v-if="item.gender === 'F'" name="cil-user-female" />
                <CIcon v-else-if="item.gender === 'M'" name="cil-user" />
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="customerCrud"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      :size="size"
    />
    <ListModal
      v-else-if="actionType == 'list'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :module="module"
      :form="form"
      :data="modalProps"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="customerCrud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      size="xl"
      ref="form"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterCustomers"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>
import moment from 'moment'
import FormModal from '../components/formModal'
import ListModal from '../components/listModal'
import ConfirmModal from '../components/confirmModal'
import FilterSidebar from '../components/filterSidebar'
import { prepareFilterParams } from "@/helpers/custom"

export default {
  name: 'Customers',
  components:{
    FormModal, ConfirmModal, FilterSidebar, ListModal
  },
  data() {
    return {
      modalTitle: '',
      size: 'sm',
      actionType: '',
      module: 'social',
      form: 'CustomerInfo',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openSidebar: false,
      filterForm: 'customerFilter',
      filterParams: {},
      lastItem: {},
      activePage: 1,
      pages: 5,
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        { key: 'id', label: 'ID', _style: 'font-size:12px' },
        { key: 'username', label: 'Kul. Adı', _style: 'font-size:12px' },
        { key: 'firstName', label: 'Ad', _style: 'font-size:12px' },
        { key: 'lastName', label: 'Soyad', _style: 'font-size:12px' },
        { key: 'gender', label: 'Cinsiyet', _style: 'font-size:12px' },
        { key: 'emailAddress', label: 'E-posta', _style: 'font-size:12px' },
        {
          key: 'emailValidFlag',
          label: 'Email_Onayı',
          _style: 'font-size:12px'
        },
        { key: 'createTime', label: 'Üyelik_Tarihi', _style: 'font-size:12px' },
        {
          key: 'lastLoginTime',
          label: 'Giriş_Tarihi',
          _style: 'font-size:12px'
        },
        {
          key: 'lastLoginDetails',
          label: 'Giriş_Bilgileri',
          _style: 'font-size:12px'
        },
        { key: 'activeFlag', label: 'Aktif', _style: 'font-size:12px' },
        {
          key: 'guestUserFlag',
          label: 'Misafir_Kullanıcı',
          _style: 'font-size:12px'
        },
        { key: 'loginType', label: 'Giriş_Tipi', _style: 'font-size:12px' }
      ],
      filters: [
        {
          type: 'numeric',
          dataIndex: 'id',
          field: 'id',
          comparison: 'eq'
        },
        {
          type: 'string',
          dataIndex: 'firstName',
          field: 'firstName',
        },
        {
          type: 'string',
          dataIndex: 'lastName',
          field: 'lastName',
        },
        {
          type: 'string',
          dataIndex: 'emailAddress',
          field: 'emailAddress',
        },
        {
          type: 'date',
          dataIndex: 'startTime',
          field: 'createTime',
          comparison: 'gt'
        },
        {
          type: 'date',
          dataIndex: 'endTime',
          field: 'createTime',
          comparison: 'lt'

        }
      ],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  computed: {
    reloadParams() {
      return [this.activePage]
    },
    customers() {
      return this.$store.getters.customers
    },
    loading() {
      return this.$store.getters.socialLoading
    }
  },
  methods: {
    async openModalEvent(actionType, form, data, title, desc){
      if(form == 'CustomerPromecodes'){
        await this.$store.dispatch('customerPromecode_list', {customerId: data.id})
      }else if(form == 'CustomerAddresses'){
        await this.$store.dispatch('customerAddress_list', {customerId: data.id})
      }else if(form == 'CustomerAlertLog'){
        var formData = new FormData()
        formData.append('filter' + '[0][field]', 'log')
        formData.append('filter' + '[0][data][type]', 'string')
        formData.append( 'filter' + '[0][data][value]', data.emailAddress )
        await this.$store.dispatch('customerAlertLog_list', formData)
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },
    dateFormat(data){
      return moment(data).format('DD.MM.YYYY hh:mm:ss')
    },
    customerCrud: async function(item, actionType){
      let params = {
        customerId: item.id,
      }
      if(actionType == 'sendNewPassword'){
        await this.$store.dispatch('customer_sendMail', params)
      }else{
        params.active = item.activeFlag
        params.emailValid = item.emailValidFlag
        await this.$store.dispatch('customer_action', params)
      }
      
      if(this.$store.getters.socialStatus.success) {
        this.filterCustomers(this.filterParams);
        this.openModalEvent()
      }
    },
    async filterCustomers(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
       
      await this.$store.dispatch('customer_list', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
   
    goToOrdersPage(item) {
      let routeData = this.$router.resolve({
        name: 'Orders',
        query: {
          customerId: item.id,
          emailAddress: item.emailAddress
        }
      })
      window.open(routeData.href, '_blank')
    },
    rowClicked(item) {
      this.lastItem = item
    },
  },
  created() {
    if (this.$route.query.customerId !== null && this.$route.query.customerId !== undefined ) {
      this.filterParams = {id: this.$route.query.customerId}
    }
    this.filterCustomers(this.filterParams)
  }
}
</script>
